// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Container, Grid, Stack, styled, useTheme } from '@mui/material'

// root
import { pageContextProps } from '../../gatsby-node'

// components
import Divider from '@atoms/dividers/Divider'
import SmallDivider from '@atoms/dividers/SmallDivider'
import ExternalLink from '@atoms/links/ExternalLink'
import FixedHeightHero from '@atoms/layout/FixedHeightHero'
import FollowButtons from '@molecules/buttonGroups/FollowButtons'
import EnquiryForm from '@molecules/forms/EnquiryForm'
import Layout from '@organisms/global/Layout'

interface Props {
  data: Queries.ContactQuery
  pageContext: PageProps & pageContextProps
}

const ImageWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: '50%',
  left: '50%',
  right: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
  zIndex: 10,
}))

const StyledImage = styled(GatsbyImage)({
  width: '100%',
  height: '100%',
  opacity: 0.7,
})

const ColHeading = ({ heading }: { heading: string }) => {
  return (
    <>
      <Box component="h2" typography="smallHeading" my={0} lineHeight={1.5}>
        {heading}
      </Box>
      <SmallDivider sx={{ mt: { xs: 1, lg: 2 }, mb: 2 }} />
    </>
  )
}

const Contact = ({ data, pageContext }: Props) => {
  const page = data.datoCmsContact
  const theme = useTheme()

  return (
    <Layout
      activeNavSlug={pageContext.activeNavSlug}
      metadata={{
        seoTags: page.seoMetaTags,
      }}
    >
      <FixedHeightHero
        sx={{ position: 'relative', textAlign: 'left', overflowX: 'hidden' }}
      >
        <ImageWrapper mx="auto" className="fixed-height">
          <StyledImage
            image={page.heroImage.gatsbyImageData}
            alt=""
            loading="eager"
          />
        </ImageWrapper>
        <Stack
          justifyContent="center"
          className="fixed-height"
          sx={{ position: 'relative', zIndex: 10 }}
        >
          <Container maxWidth="xl" sx={{ py: 8 }}>
            <Stack direction="row" alignItems="center">
              <Divider
                sx={{
                  width: theme.spacing(200),
                  ml: -199,
                }}
              />
              <Box ml={2} typography="smallHeading" fontWeight="fontWeightThin">
                {page.smallHeading}
              </Box>
            </Stack>
            <Box typography="xl" mt={2}>
              {page.pageTitle}
            </Box>
            <Divider
              colorVariant="light-trans"
              sx={{
                mt: { xs: 1, xl: theme.spacing(4) },
                mb: { xs: 2, xl: theme.spacing(4) },
              }}
            />
            <Grid container spacing={4}>
              <Grid item xs={12} xl={4}>
                <Box
                  typography="largeText"
                  dangerouslySetInnerHTML={{
                    __html: page.visitingMessageNode.childMarkdownRemark.html,
                  }}
                  sx={{ ['&, p']: { marginTop: 0 } }}
                />
              </Grid>
              <Grid item xs={12} xl={4}>
                <ColHeading heading={page.contactDetailsHeading} />
                <Box
                  fontSize="2.8rem"
                  fontFamily={theme.typography.fontFamilySerif}
                  fontWeight={theme.typography.fontWeightThin}
                >
                  <Box component="ul" sx={{ listStyle: 'none', margin: 0 }}>
                    <li>
                      <ExternalLink
                        href={`tel:${page.telephoneNumber
                          .replace('(0)', '')
                          .replace(/\s/g, '')}`}
                      >
                        {page.telephoneNumber}
                      </ExternalLink>
                    </li>
                    <li>
                      <ExternalLink href={`mailto:${page.emailAddress}`}>
                        {page.emailAddress}
                      </ExternalLink>
                    </li>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} xl={4} sx={{ mt: { xs: 2, lg: 0 } }}>
                <ColHeading heading={page.socialHeading} />
                <FollowButtons orientation="vertical" />
              </Grid>
            </Grid>
          </Container>
        </Stack>
      </FixedHeightHero>
      <Box
        component="section"
        bgcolor="primary.light"
        pt={{ xs: 6, md: 7, xl: 8 }}
        pb={{ xs: 9 }}
      >
        <Container maxWidth="lg">
          <EnquiryForm />
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query Contact {
    datoCmsContact {
      contactDetailsHeading
      emailAddress
      pageTitle
      smallHeading
      socialHeading
      telephoneNumber
      visitingMessageNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          width: 1600
          layout: FULL_WIDTH
          imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
        )
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

export default Contact
